import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { screen } from "../components/common/variables";
import PageTitle from "../components/page-title";

const Wrapper = styled.div`
  padding-top: 91px;
  padding-bottom: 200px;
  min-height: 80vh;

  .content {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 140px;
    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 80px;
    }
    @media ${screen.medium} {
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }
  }
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Wrapper>
        <div className="content">
          <PageTitle>Privacy Policy</PageTitle>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPage;
